import React, { useEffect } from "react";
import { TweenMax, TimelineMax, Power4 } from "gsap/TweenMax";
import styled from "styled-components";
import useWindowSize from "src/hooks/useWindowSize";

import Number from "src/images/contact/onethirty.inline.svg";
import Scale from "src/images/contact/scale.png";
import Four from "src/images/contact/four.png";
import Five from "src/images/contact/five.png";
import Launchpad from "src/images/contact/launchpad.png";
import OCAD from "src/images/contact/ocad.png";
import GB from "src/images/contact/gb.png";
import TorontoFoundation from "src/images/contact/tfoundation.png";
import Microsoft from "src/images/contact/microsoft.png";
import Bell from "src/images/contact/bell.png";
import Stack from "src/images/contact/stack.png";
import Twitter from "src/images/contact/twitter.png";
import Toronto from "src/images/contact/toronto.png";
import Daniels from "src/images/contact/daniels.png";

import SEO from "src/components/seo.js";

const Viewport = styled.div`
  height: 6000px;
  opacity: 0;
  visibility: hidden;
`;

const Mask = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 15%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 85%,
    rgba(0, 0, 0, 1) 100%
  );
`;

const Scene3DContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 500px;
  perspective-origin: 50% 50%;
  transform: translate3d(0px, 0px, 0px);
`;

const Scene3D = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transform: translateZ(0px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// container that gets transformed on scroll
const InfoContainer = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Section1 = styled.div`
  transform-style: preserve-3d;
  width: 50%;
  height: 500px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 15% 50% 10% 15%;
  align-content: center;
  margin: 0;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 75%;
  }

  @media (max-width: 500px) {
    width: 100%;
    grid-template-rows: 5% 10% 35% 7.5% 10%;
  }
`;

const Section1Text = styled.p`
  font-family: "Univers";
  color: white;
  font-size: 14px;
  margin: 0;
  justify-self: center;
  align-self: center;
  @media (max-width: 330px) {
    font-size: 12px;
  }
`;

const Section1Email = styled.a`
  font-family: "URWAccidalia";
  color: white;
  font-size: 30px;
  border: 2px solid white;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  justify-self: center;
  align-self: center;
  letter-spacing: 2px;
  background: black;
  transition: color 0.5s ease, background 0.5s ease;
  @media (max-width: 500px) {
    font-size: 22px;
    padding-left: 35px;
    padding-right: 35px;
  }
  &:hover {
    color: black;
    background: white;
  }
`;

const Section1Address = styled.p`
  font-family: "Univers";
  color: white;
  font-size: 28px;
  text-align: left;
  letter-spacing: 1px;
  margin: 0;
  justify-self: center;
  align-self: start;

  @media (max-width: 800px) {
    font-size: 26px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
    letter-spacing: 0;
  }
  @media (max-width: 330px) {
    font-size: 16px;
    letter-spacing: 0;
  }
`;

const Section1Number = styled.div`
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Section1Scale = styled.img`
  height: 50px;
  transform-style: preserve-3d;
  width: 575px;
  margin: 0;
  justify-self: center;
  align-self: center;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Section2 = styled.div`
  transform-style: preserve-3d;
  width: 50%;
  height: 600px;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6% 10% 84%;
  align-content: center;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 75%;
  }

  @media (max-width: 500px) {
    width: 100%;
    grid-template-rows: 10% 15% 55%;
  }

  @media (max-width: 350px) {
    width: 100%;
    grid-template-rows: 6% 15% 40%;
  }
`;

const Section2FloorText = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  justify-self: center;
  align-self: center;
`;

const Section2FloorTextTitle = styled.p`
  font-family: "URWAccidalia";
  color: white;
  font-size: 24px;
  justify-self: center;
  align-self: center;
  margin: 0;
`;

const Section2FloorTextDescription = styled.p`
  font-family: "Univers";
  color: white;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1px;
  line-height: 1.2;
  justify-self: center;
  align-self: center;
  margin: 0;
  width: 100%;

  @media (max-width: 1200px) {
    letter-spacing: 0;
    line-height: 1.2;
  }

  @media (max-width: 800px) {
    letter-spacing: 0;
  }
`;

const Section2Floor = styled.img`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  margin: 0;
  justify-self: start;
  align-self: center;

  @media (max-width: 500px) {
    height: auto;
    width: 375px;
  }

  @media (max-width: 350px) {
    height: auto;
    width: 300px;
  }
`;

const Section2FloorTitle = styled.div`
  position: absolute;
  bottom: 10%;
  right: 0;
  width: 30%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: center;
  @media (max-width: 500px) {
    bottom: 15%;
  }
  h3 {
    font-family: "URWAccidalia";
    color: white;
    font-size: 18px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
  p {
    margin-top: 5px;
    font-family: "Univers";
    color: white;
    font-size: 16px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  @media (max-width: 350px) {
    bottom: 20%;
  }
`;

const Section3 = styled.div`
  transform-style: preserve-3d;
  width: 50%;
  height: 500px;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 6% 15% 56% 10% 12%;
  align-content: center;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 75%;
    grid-template-rows: 5% 15% 60% 10% 10%;
  }

  @media (max-width: 500px) {
    width: 100%;
    grid-template-rows: 5% 22% 38% 8% 12%;
  }

  @media (max-width: 330px) {
    grid-template-rows: 5% 24% 36% 8% 12%;
  }
`;

const Section3FloorText = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  justify-self: center;
  align-self: center;
`;

const Section3FloorTextTitle = styled.p`
  font-family: "URWAccidalia";
  color: white;
  font-size: 24px;
  justify-self: center;
  align-self: center;
  margin: 0;
`;

const Section3FloorTextDescription = styled.p`
  font-family: "Univers";
  color: white;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1px;
  line-height: 1.2;
  justify-self: center;
  align-self: center;
  margin: 0;
  width: 100%;

  @media (max-width: 1200px) {
    letter-spacing: 0;
    line-height: 1.2;
  }

  @media (max-width: 800px) {
    letter-spacing: 0;
  }
`;

const Section3Floor = styled.img`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  margin: 0;
  justify-self: start;
  align-self: center;
`;

const Section3FloorTitle = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: center;
  align-self: flex-start;

  h3 {
    font-family: "URWAccidalia";
    color: white;
    font-size: 18px;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 5px;
    font-family: "Univers";
    color: white;
    font-size: 16px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
`;

const Section3Credit = styled.a`
  width: 98%;
  margin: 0;
  justify-self: start;
  align-self: center;
  border: 2px solid white;
  padding: 5px;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  background: black;
  transition: color 0.5s ease, background 0.5s ease;
  &:hover {
    background: white;
    color: black;
  }
  &:hover p {
    color: black;
  }
`;

const Section3CreditFloor = styled.p`
  color: black;
  background: white;
  font-family: "URWAccidalia";
  font-size: 14px;
  justify-self: center;
  align-self: center;
  padding: 5px 3px 3px 3px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const Section3CreditLink = styled.p`
  color: white;
  text-decoration: none;
  font-family: "Univers";
  font-size: 14px;
  justify-self: center;
  align-self: center;
  padding-top: 2px;
  transition: color 0.5s ease, background 0.5s ease;
  @media (max-width: 1200px) {
    width: 60%;
    text-align: left;
  }
  @media (max-width: 800px) {
    text-align: left;
  }
  @media (max-width: 500px) {
    font-size: 10px;
    text-align: center;
  }
`;

const Section4 = styled.div`
  transform-style: preserve-3d;
  width: 50%;
  height: 500px;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 35% 20% 15%;
  align-content: center;

  @media (max-width: 1200px) {
    width: 60%;
    grid-template-rows: 5% 35% 20% 15%;
  }

  @media (max-width: 1000px) {
    grid-template-rows: 5% 35% 20% 15% 15%;
  }

  @media (max-width: 800px) {
    width: 75%;
    grid-template-rows: 5% 35% 20% 15% 15%;
  }

  @media (max-width: 500px) {
    width: 100%;
    grid-template-rows: 5% 32% 10% 10% 10%;
  }
`;

const Section4PartnerText = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  justify-self: center;
  align-self: center;
`;

const Section4PartnerTextTitle = styled.p`
  font-family: "URWAccidalia";
  color: white;
  font-size: 24px;
  justify-self: center;
  align-self: center;
  margin: 0;
`;

const Section4PartnerTextDescription = styled.p`
  font-family: "Univers";
  color: white;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1.5px;
  line-height: 1.2;
  justify-self: center;
  align-self: center;
  margin: 0;
  width: 100%;

  @media (max-width: 1000px) {
    line-height: 1.1;
  }

  @media (max-width: 500px) {
    letter-spacing: 0;
    font-size: 14px;
    width: 100%;
  }
`;

const Section4Sponsors = styled.div`
  width: 100%;
  margin: 0;
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: space-around;
`;

const Section4SponsorsImgTop = styled.img`
  margin: 0;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 1100px) {
    height: 32px;
    margin-left: 8px;
    margin-right: 8px;
  }

  @media (max-width: 500px) {
    height: 26px;
  }
`;

const Section4SponsorsImg = styled.img`
  margin: 0;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 1100px) {
    height: 22px;
    margin-left: 8px;
    margin-right: 8px;
  }

  @media (max-width: 500px) {
    height: 20px;
  }
`;

const Section5 = styled.div`
  transform-style: preserve-3d;
  width: 50%;
  height: 500px;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 25% 20% 15%;
  align-content: center;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 800px) {
    width: 75%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Section5DonateText = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  justify-self: center;
  align-self: center;
`;

const Section5DonateTextTitle = styled.p`
  font-family: "URWAccidalia";
  color: white;
  font-size: 24px;
  justify-self: center;
  align-self: center;
  margin: 0;
`;

const Section5DonateTextDescription = styled.p`
  font-family: "Univers";
  color: white;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1.5px;
  line-height: 1.2;
  justify-self: center;
  align-self: center;
  margin: 0;
  width: 100%;

  @media (max-width: 1000px) {
    line-height: 1.1;
  }

  @media (max-width: 500px) {
    letter-spacing: 0;
    font-size: 14px;
    width: 100%;
  }
`;

const Section5Donate = styled.a`
  width: 100%;
  justify-self: center;
  align-self: center;
  border: 2px solid white;
  margin: 0;
  padding: 15px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "Univers";
  color: white;
  background: black;
  transition: color 0.5s ease, background 0.5s ease;
  &:hover {
    background: white;
    color: black;
  }
`;

const Section5CopyRight = styled.div`
  width: 100%;
  margin: 0;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Section5CopyRightText = styled.p`
  font-family: "Univers", sans-serif;
  color: white;
  font-size: 14px;
  margin: 0;
`;

const TitleContainer = styled.div`
  height: 100vh;
  position: fixed;
  align-items: center;
  font-family: "URWAccidalia", sans-serif;
  font-size: 42pt;
  z-index: 1000;
  color: transparent;

  @media (max-width: 1150px) {
    display: none;
  }
`;

const SectionTitle = styled.h1`
  writing-mode: vertical-rl;
  -webkit-text-stroke: 1px white;
  position: absolute;
  left: 0;
  margin-top: 15vh;
  opacity: 0;
  z-index: 1000;
  margin-left: 16px;
`;

const ContactPage = () => {
  // width hook to adjust component design based on screen width
  const { width } = useWindowSize();
  // parameters to run animations based on scroll
  let scrollTotal = 0;
  let scrollPosY = 0;

  useEffect(() => {
    // set up panels at start positions to run animation
    TweenMax.set("#text1", { y: 0, z: -150, rotationX: 0 });
    TweenMax.set("#text2", { y: 350, z: 150, rotationX: 90 });
    TweenMax.set("#text3", { y: 500, z: 400, rotationX: 120 });
    TweenMax.set("#text4", { y: 500, z: 400, rotationX: 120 });
    TweenMax.set("#text5", { y: 500, z: 400, rotationX: 120 });

    // create timeline controlled by scroll
    const tl = new TimelineMax({ paused: true });

    tl.add("transition1", "+=0");
    tl.to("#text1", 20, { y: -350, z: 150, rotationX: -90 }, "transition1");

    tl.add("transition1.5", "-=19");
    tl.to("#text2", 20, { y: 0, z: -150, rotationX: 0 }, "transition1.5");
    tl.to("#text3", 20, { y: 350, z: 200, rotationX: 90 }, "transition1.5");

    tl.add("transition2", "+=0");
    tl.to("#text1", 20, { y: -500, z: 400, rotationX: -120 }, "transition2");
    tl.to("#text2", 20, { y: -350, z: 150, rotationX: -90 }, "transition2");

    tl.add("transition2.5", "-=19");
    tl.to("#text3", 20, { y: 0, z: -150, rotationX: 0 }, "transition2.5");
    tl.to("#text4", 20, { y: 350, z: 150, rotationX: 90 }, "transition2.5");

    tl.add("transition3", "+=0");
    tl.to("#text2", 20, { y: -500, z: 400, rotationX: -120 }, "transition3");
    tl.to("#text3", 20, { y: -350, z: 150, rotationX: -90 }, "transition3");

    tl.add("transition3.5", "-=19");
    tl.to("#text4", 20, { y: 0, z: -150, rotationX: 0 }, "transition3.5");
    tl.to("#text5", 20, { y: 350, z: 150, rotationX: 90 }, "transition3.5");

    tl.add("transition4", "+=0");
    tl.to("#text3", 20, { y: -500, z: 400, rotationX: -120 }, "transition4");
    tl.to("#text4", 20, { y: -350, z: 150, rotationX: -90 }, "transition4");

    tl.add("transition4.5", "-=19");
    tl.to("#text5", 20, { y: 0, z: -150, rotationX: 0 }, "transition4.5");

    const element = document.querySelector("#SectionTitle");
    tl.to(
      "#SectionTitle",
      tl.duration(),
      { y: window.innerHeight - (element.offsetTop + element.offsetHeight) },
      "0"
    );
    //Updates timeline based on scroll
    const handleScroll = () => {
      requestAnimationFrame(animateScroll);
    };

    const animateScroll = () => {
      scrollTotal = document.body.scrollHeight - window.innerHeight;
      scrollPosY = window.scrollY;

      const posPercent = Math.abs(scrollPosY / scrollTotal);
      tl.progress(posPercent);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    TweenMax.set("#SectionTitle", { y: -200, autoAlpha: 0 });
    TweenMax.to("#SectionTitle", 1, {
      y: 0,
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });
    TweenMax.to("#contactViewport", 1.25, {
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // change sponsors section based on width of screen
  let sponsorSection;
  if (width > 1000) {
    sponsorSection = (
      <Section4Sponsors>
        <Section4SponsorsImg src={Microsoft}></Section4SponsorsImg>
        <Section4SponsorsImg src={Bell}></Section4SponsorsImg>
        <Section4SponsorsImg src={Stack}></Section4SponsorsImg>
        <Section4SponsorsImg src={Twitter}></Section4SponsorsImg>
        <Section4SponsorsImg src={Toronto}></Section4SponsorsImg>
        <Section4SponsorsImg src={Daniels}></Section4SponsorsImg>
      </Section4Sponsors>
    );
  } else {
    sponsorSection = (
      <>
        <Section4Sponsors>
          <Section4SponsorsImg src={Microsoft}></Section4SponsorsImg>
          <Section4SponsorsImg src={Bell}></Section4SponsorsImg>
          <Section4SponsorsImg src={Stack}></Section4SponsorsImg>
        </Section4Sponsors>
        <Section4Sponsors>
          <Section4SponsorsImg src={Twitter}></Section4SponsorsImg>
          <Section4SponsorsImg src={Toronto}></Section4SponsorsImg>
          <Section4SponsorsImg src={Daniels}></Section4SponsorsImg>
        </Section4Sponsors>
      </>
    );
  }

  return (
    <>
      <SEO title="HXOUSE · Contact" />
      <Mask></Mask>
      <TitleContainer>
        <SectionTitle id="SectionTitle">CONTACT</SectionTitle>
      </TitleContainer>
      <Viewport id="contactViewport">
        <Scene3DContainer>
          <Scene3D id="scene3D">
            <InfoContainer id="text1">
              <Section1 id="section1">
                <Section1Text>
                  For any questions or additional information please contact
                </Section1Text>
                <Section1Email href="mailto:info@hxouse.com">
                  INFO@HXOUSE.COM
                </Section1Email>
                <Section1Number>
                  <Number />
                </Section1Number>
                <Section1Address>
                  QUEENS QUAY E, TORONTO, ON M5A 3Y5
                </Section1Address>
                <Section1Scale src={Scale}></Section1Scale>
              </Section1>
            </InfoContainer>
            <InfoContainer id="text2">
              <Section2 id="section2">
                <Section2FloorText>
                  <Section2FloorTextTitle>FLR &ndash;</Section2FloorTextTitle>
                  <Section2FloorTextTitle>004</Section2FloorTextTitle>
                </Section2FloorText>
                <Section2FloorTextDescription>
                  HXOUSE @ Launchpad is currently located on the 4th floor of
                  130 Queens Quay East at the Artscape Launchpad, a new $28.4
                  million entrepreneurship center.
                </Section2FloorTextDescription>
                <Section2Floor src={Four}></Section2Floor>
                <Section2FloorTitle>
                  <h3>004</h3>
                  <p>Tenant Room</p>
                </Section2FloorTitle>
              </Section2>
            </InfoContainer>
            <InfoContainer id="text3">
              <Section3 id="section3">
                <Section3FloorText>
                  <Section3FloorTextTitle>FLR &ndash;</Section3FloorTextTitle>
                  <Section3FloorTextTitle>005</Section3FloorTextTitle>
                </Section3FloorText>
                <Section3FloorTextDescription>
                  HXOUSE will continue to grow their community incubator at
                  Artscape Daniels Launchpad while announcing the addition of
                  our 7,375 square feet digital accelerator located exclusively
                  on the 5th floor. Slated to be open Q4.
                </Section3FloorTextDescription>
                <Section3Floor src={Five}></Section3Floor>
                <Section3FloorTitle>
                  <h3>005</h3>
                  <p>Private Office</p>
                </Section3FloorTitle>
                <Section3Credit
                  href="https://www.quadrangle.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Section3CreditFloor>FLR - 004</Section3CreditFloor>
                  <Section3CreditLink>
                    INTERIOR &amp; ARCHITECTURE - WWW.QUADRANGLE.CA
                  </Section3CreditLink>
                  <Section3CreditFloor>FLR - 005</Section3CreditFloor>
                </Section3Credit>
              </Section3>
            </InfoContainer>
            <InfoContainer id="text4">
              <Section4 id="section4">
                <Section4PartnerText>
                  <Section4PartnerTextTitle>PARTNERS</Section4PartnerTextTitle>
                  <Section4PartnerTextTitle>&ndash;</Section4PartnerTextTitle>
                </Section4PartnerText>
                <Section4PartnerTextDescription>
                  HXOUSE prides itself on only partnering with organizations who
                  have made a deep commitment to supporting the creative
                  ecosystem. Partners work together with HXOUSE to develop
                  dynamic programming, creating advanced career oppurtunities
                  and offering a range of supports to help accelerate the growth
                  and development of the overall creative sector. Without our
                  partner's generosity, none of this would be possible.
                </Section4PartnerTextDescription>
                <Section4Sponsors>
                  <Section4SponsorsImgTop
                    src={Launchpad}
                  ></Section4SponsorsImgTop>
                  <Section4SponsorsImgTop src={OCAD}></Section4SponsorsImgTop>
                  <Section4SponsorsImgTop src={GB}></Section4SponsorsImgTop>
                  <Section4SponsorsImgTop
                    src={TorontoFoundation}
                  ></Section4SponsorsImgTop>
                </Section4Sponsors>
                {sponsorSection}
              </Section4>
            </InfoContainer>
            <InfoContainer id="text5">
              <Section5 id="section5">
                <Section5DonateText>
                  <Section5DonateTextTitle>DONORS</Section5DonateTextTitle>
                  <Section5DonateTextTitle>&ndash;</Section5DonateTextTitle>
                </Section5DonateText>
                <Section5DonateTextDescription>
                  Many of our programs would not be possible without the
                  generous support of our donors. Contributions to our charity
                  can be made through the Toronto Foundation. For more
                  information on how to get involved and support our mission
                  please contact donors@hxouse.com.
                </Section5DonateTextDescription>
                <Section5Donate
                  href="https://torontofoundation.ca/hxouse-foundation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CLICK HERE TO SUPPORT HXOUSE
                </Section5Donate>
                <Section5CopyRight>
                  <Section5CopyRightText>
                    HXOUSE FOUNDATION INC.
                  </Section5CopyRightText>
                  <Section5CopyRightText>
                    130 QUEENS QUAY E. TORONTO ON M5A 3Y5
                  </Section5CopyRightText>
                  <Section5CopyRightText>
                    ©2019 ALL RIGHTS RESERVED
                  </Section5CopyRightText>
                </Section5CopyRight>
              </Section5>
            </InfoContainer>
          </Scene3D>
        </Scene3DContainer>
      </Viewport>
    </>
  );
};

export default ContactPage;
